import { gql } from 'graphql-request'

export const GetTypingPreviousLotsData = gql`
  query typingPreviousLotsData($referenceNumber: String!) {
    listPreviousWoolLots(request: { woolLotReferenceNumber: $referenceNumber }) {
      results {
        baleHeaderPurchaseDescription
        coreTestBoxLocation
        coreTestCoefficientOfVariationLaserscan
        coreTestColourYZ
        coreTestCurvatureMeanLaser
        coreTestHauteurLength
        coreTestMicron
        coreTestMicronAirflow
        coreTestMicronLaserscan
        coreTestMicronOfda
        coreTestPointOfBreakBase
        coreTestPointOfBreakMid
        coreTestPointOfBreakTip
        coreTestStapleAverageStrength
        coreTestStapleCoefficientOfLengthVariation
        coreTestStapleLengthGreasy
        coreTestStapleStrength
        coreTestVegetableMatterBase
        coreTestYield1
        purchaseAccountName
        woolLotBales
        woolLotCode
        woolLotReferenceNumber
        woolLotSaleNumber
        woolLotSeason
        woolLotSoldStatus
        woolLotsTotalKilosClean
        woolLotsTotalKilosNet
        woolLotUserText3
        qualityScheme
        qualitySchemeWarning
        woolLotActions
        woolLotTypeCurrent
        woolLotInternalComment
        woolLotCommissionPaymentDate
      }
    }
  }
`
