import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { Grid, Flex, Stack } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { TypingProgramDetails } from '@/components/typing/program/typing-program-details/typing-program-details'
import { WoolLotLookup } from '@/components/shared/wool-lot-lookup/wool-lot-lookup'
import { UpdateBoxLocationGql } from '@/graphql/mutations/update-box-location.graphql'
import { GetTypingProgramData } from '@/graphql/queries/typing-program-data.graphql'
import {
  useAuthenticatedGraphQuery,
  useAuthenticatedGraphMutation,
} from '@/hooks/use-authenticated-query'
import { TypingProgramHeader } from '@/components/typing/program/typing-program-header/typing-program-header'
import {
  InitialTypingValues,
  TypingProgramForm,
} from '@/components/typing/program/typing-program-form/typing-program-form'
import { processTypingData } from '@/helpers/typing/program/typing-program-data'
import { UpdateTypingActionsGql } from '@/graphql/mutations/update-typing-actions.graphql'
import { PAGE_TITLES } from '@/constants/page-titles'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'
import { outMessage } from '@/pages/constants'
import { SetTypingTypeCurrentGql } from '@/graphql/mutations/set-typing-type-current.graphql'
import { SetTypingInternalCommentGql } from '@/graphql/mutations/set-typing-internal-comment.graphql'
import { OutletContextProps } from '@/components/shared/app-shell/app-shell'
import { GetTypingContractsData } from '@/graphql/queries/typing-contracts-data.graphql'
import { GetTypingPreviousLotsData } from '@/graphql/queries/typing-previous-lots-data.graphql'
import { ContractsTable } from '@/components/typing/program/contracts-table/contracts-table'
import { PreviousLotsTable } from '@/components/typing/program/previous-lots-table/previous-lots-table'
import { WoolLotDataProps } from '@/components/typing/program/typing-program-types'
import { Collapse } from '@/components/shared/collapse/collapse'

export function TypingProgram() {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const referenceNumberFromParams = queryParams.get('reference')
  const [noResults, setNoResults] = useState<boolean>(false)
  const [lastReferenceNumber, setLastReferenceNumber] = useState<string | null>(null)
  const [initialTypingValues, setInitialTypingValues] = useState<InitialTypingValues | null>(null)
  const queryClient = useQueryClient()

  function handleReferenceNumberUpdate(newReferenceNumber: string | null) {
    // update query parameters
    if (newReferenceNumber === null) {
      if (referenceNumberFromParams) {
        queryParams.set('reference', referenceNumberFromParams)
      } else {
        queryParams.delete('reference')
      }
    } else {
      queryParams.set('reference', newReferenceNumber)
      if (
        newReferenceNumber === referenceNumberFromParams ||
        newReferenceNumber === lastReferenceNumber
      ) {
        refetchMainLine()
        refetchContracts()
        refetchPreviousLots()
        return
      }
    }

    setLastReferenceNumber(newReferenceNumber)
    // create new search string and navigate to it
    const newSearch = `?${queryParams.toString()}`
    navigate({ search: newSearch })
  }

  // Get the typing program data
  const {
    data: typingData,
    isLoading: typingDataLoading,
    isFetching: typingDataFetching,
    refetch: refetchMainLine,
  } = useAuthenticatedGraphQuery({
    queryKey: ['typingProgramData', referenceNumberFromParams],
    gql: GetTypingProgramData,
    queryParams: { referenceNumber: referenceNumberFromParams },
    enabled: !!referenceNumberFromParams,
    alwaysThrowOnError: true,
  })

  const typingDataLoadingOrFetching = typingDataLoading || typingDataFetching

  // Set variable which controls if no results message should be displayed
  useEffect(() => {
    setNoResults(!typingDataLoadingOrFetching && !!typingData && !typingData?.typingProgramData)
  }, [typingDataLoadingOrFetching, typingData])

  // Get the typing contracts data
  const {
    data: contractsData,
    isLoading: contractsDataLoading,
    isFetching: contractsDataFetching,
    refetch: refetchContracts,
  } = useAuthenticatedGraphQuery({
    queryKey: ['contractsData', referenceNumberFromParams],
    gql: GetTypingContractsData,
    queryParams: { referenceNumber: referenceNumberFromParams },
    enabled: !!referenceNumberFromParams,
    alwaysThrowOnError: true,
  })

  const contractsDataLoadingOrFetching = contractsDataLoading || contractsDataFetching

  // Get the previous lots data
  const {
    data: previousLotsData,
    isLoading: previousLotsDataLoading,
    isFetching: previousLotsDataFetching,
    refetch: refetchPreviousLots,
  } = useAuthenticatedGraphQuery({
    queryKey: ['previousLotsData', referenceNumberFromParams],
    gql: GetTypingPreviousLotsData,
    queryParams: { referenceNumber: referenceNumberFromParams },
    enabled: !!referenceNumberFromParams,
    alwaysThrowOnError: true,
  })

  const previousLotsDataLoadingOrFetching = previousLotsDataLoading || previousLotsDataFetching

  // Pass the initial typing values to the form component anytime the "main line" changes
  useEffect(() => {
    setInitialTypingValues({
      actions: typingData?.typingProgramData?.woolLotActions ?? '',
      comment: typingData?.typingProgramData?.woolLotInternalComment ?? '',
      type: typingData?.typingProgramData?.woolLotTypeCurrent ?? '',
    })
  }, [typingData?.typingProgramData?.woolLotId, typingDataLoadingOrFetching])

  const blUpdate = useAuthenticatedGraphMutation({
    gql: UpdateBoxLocationGql,
    gqlKey: 'setBoxLocation',
    onError: async () =>
      // Execute 'cache busting' by running invalidateQueries when mutation returns any errors
      queryClient.invalidateQueries({ queryKey: ['typingProgramData', referenceNumberFromParams] }),
  })

  function setBoxLocation(newBoxLocationInput: string | null) {
    let boxLocation: string | null = newBoxLocationInput
    // This is to support scanning special "OUT" bar-codes which should result in clearing the box location field
    if (newBoxLocationInput?.includes(outMessage)) {
      boxLocation = ''
    }
    blUpdate.mutate({ coreTestId: typingData?.typingProgramData?.coreTestId, boxLocation })
    // To avoid refetching on mutation success, we need to assume mutation will be successful and override current typing program data
    queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
      typingProgramData: {
        ...typingData?.typingProgramData,
        coreTestBoxLocation: boxLocation,
      },
    })
  }

  // Update the typing actions
  const actionsUpdate = useAuthenticatedGraphMutation({
    gql: UpdateTypingActionsGql,
    gqlKey: 'setTypingActions',
    onError: async () =>
      // Execute 'cache busting' by running invalidateQueries when mutation returns any errors
      queryClient.invalidateQueries({ queryKey: ['typingProgramData', referenceNumberFromParams] }),
  })

  const mutateActions = (actions: string) => {
    actionsUpdate.mutate({ woolLotId: typingData?.typingProgramData?.woolLotId, actions })
  }

  // Update the typing type current
  const typeUpdate = useAuthenticatedGraphMutation({
    gql: SetTypingTypeCurrentGql,
    gqlKey: 'setTypingTypeCurrent',
    onError: async () =>
      // Execute 'cache busting' by running invalidateQueries when mutation returns any errors
      queryClient.invalidateQueries({ queryKey: ['typingProgramData', referenceNumberFromParams] }),
  })

  const mutateType = (typeCurrent: string) => {
    typeUpdate.mutate({ woolLotId: typingData?.typingProgramData?.woolLotId, typeCurrent })
  }

  // Update the typing internal comment
  const commentUpdate = useAuthenticatedGraphMutation({
    gql: SetTypingInternalCommentGql,
    gqlKey: 'setTypingInternalComment',
    onError: async () =>
      // Execute 'cache busting' by running invalidateQueries when mutation returns any errors
      queryClient.invalidateQueries({ queryKey: ['typingProgramData', referenceNumberFromParams] }),
  })

  const mutateComment = (internalComment: string) => {
    commentUpdate.mutate({ woolLotId: typingData?.typingProgramData?.woolLotId, internalComment })
  }

  // Process typing data for displaying
  const typingProgramDisplayData = processTypingData(typingData?.typingProgramData)
  const typingPreviousLotsDisplayData = (): WoolLotDataProps[] => {
    const results = previousLotsData?.listPreviousWoolLots?.results ?? []
    return results.map(processTypingData).filter((data) => !!data)
  }

  // Prevent navigating away from the page if there are unsaved changes
  // Not using a Mantine form for this page to track the form (values, dirty state, etc)
  // as the form has specific interactions with the scanner that need to be handled.
  const [unSavedChanges, setUnSavedChanges] = useState<boolean>(false)
  useEffect(() => {
    setUnSavedChanges(actionsUpdate.isPending || commentUpdate.isPending || typeUpdate.isPending)
  }, [commentUpdate.isPending, typeUpdate.isPending, actionsUpdate.isPending])

  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (unSavedChanges) {
        event.preventDefault()
        return true
      }
      return null // Allow navigation if no conditions met
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [unSavedChanges])

  const [
    isPreviousLotsTableOpen,
    { toggle: togglePreviousLotsTable, open: openPreviousLotsTable },
  ] = useDisclosure(true)
  const [isContractsTableOpen, { toggle: toggleContractsTable, open: openContractsTable }] =
    useDisclosure(true)

  useEffect(() => {
    openPreviousLotsTable()
    openContractsTable()
  }, [referenceNumberFromParams])

  /**
   * Adapt column widths based on container width (affected by menu
   * collapsed/expanded state on narrower view ports)
   */
  const outletContext: OutletContextProps = useOutletContext()
  const isWideContainer = outletContext?.isWideContainer

  // Construct heading for Previous Lots table based on available data
  const stationRecordNumber = typingData?.typingProgramData?.stationRecordNumber || ''
  const purchaseBrand = typingData?.typingProgramData?.baleHeaderPurchaseBrand || ''
  const previousLotsHeading = `Previous Lots ${stationRecordNumber} ${purchaseBrand}`

  return (
    <PageContainer title={PAGE_TITLES.TYPING_PROGRAM} collapseMenu>
      <Grid gutter="xl">
        <Grid.Col span={{ base: 12, xl: isWideContainer ? 3 : 4 }}>
          <Flex
            direction={{ base: 'column-reverse', xl: 'row' }}
            justify="space-between"
            align="flex-start"
            gap="md"
            mb="xl"
          >
            <TypingProgramHeader
              woolLotReferenceNumber={typingProgramDisplayData?.woolLotReferenceNumber}
              baleHeaderPurchaseBrand={typingProgramDisplayData?.baleHeaderPurchaseBrand}
              baleHeaderPurchaseDescription={
                typingProgramDisplayData?.baleHeaderPurchaseDescription
              }
              loadingState={typingDataLoading}
            />
            <WoolLotLookup
              setReferenceNumber={handleReferenceNumberUpdate}
              setBoxLocation={setBoxLocation}
              lookupResponse={{
                woolLotRecordNumber: typingData?.typingProgramData?.woolLotReferenceNumber,
                coreTestId: typingData?.typingProgramData?.coreTestId,
                isLoading: typingDataLoadingOrFetching,
                isSuccess: !typingDataLoadingOrFetching && !!typingData?.typingProgramData,
              }}
              boxLocationValue={typingProgramDisplayData?.coreTestBoxLocation}
              isVerticalLayout
            />
          </Flex>
          <TypingProgramForm
            referenceNumberFromParams={referenceNumberFromParams}
            noResults={noResults}
            loadingState={typingDataLoadingOrFetching}
            updateActions={mutateActions}
            updateComment={mutateComment}
            updateType={mutateType}
            updating={{
              actions: actionsUpdate.isPending,
              comment: commentUpdate.isPending,
              type: typeUpdate.isPending,
            }}
            initialTypingValues={initialTypingValues}
          />
        </Grid.Col>
        {!!typingData?.typingProgramData && (
          <>
            <Grid.Col span={{ base: 12, xl: isWideContainer ? 9 : 8 }}>
              <Stack gap="xl">
                <TypingProgramDetails
                  data={typingProgramDisplayData}
                  isLoading={typingDataLoading}
                />
                <Collapse
                  buttonLabel="Season Contracts"
                  isOpen={isContractsTableOpen}
                  toggle={toggleContractsTable}
                >
                  <ContractsTable
                    isLoading={contractsDataLoadingOrFetching}
                    data={contractsData?.contractsData ?? []}
                  />
                </Collapse>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12 }}>
              <Collapse
                buttonLabel="Previous Lots"
                sectionHeading={previousLotsHeading}
                isOpen={isPreviousLotsTableOpen}
                toggle={togglePreviousLotsTable}
              >
                <PreviousLotsTable
                  isLoading={previousLotsDataLoadingOrFetching}
                  data={typingPreviousLotsDisplayData() ?? []}
                />
              </Collapse>
            </Grid.Col>
          </>
        )}
      </Grid>
    </PageContainer>
  )
}
