import { request } from 'graphql-request'
import { SilentRequest, RedirectRequest } from '@azure/msal-browser'
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { Query, Mutation, MutationResponse } from '@/loom-gql/graphql'
import { loginRequest } from '@/auth-config'
import { configService } from '@/services/config-service'
import { useGetAuthToken } from '@/hooks/use-get-auth-token'
import { showErrorNotification } from '@/helpers/notifications/notifications'

// All mutation return a standardized response object (MutationResponse) with a success flag and an optional error message
// except for these (which are used by the Syncing process)
type CustomMutations = Omit<
  Mutation,
  | 'setBaleHeader'
  | 'setCoreTest'
  | 'setPurchaseAccount'
  | 'setStation'
  | 'setWoolLot'
  | '__typename'
>

type GraphProps = {
  gql: string | null
  queryParams?: object
  msalRequest?: SilentRequest | RedirectRequest
}

type GraphQueryFunction = (
  props: GraphProps &
    UseQueryOptions & {
      alwaysThrowOnError?: boolean
    }
) => UseQueryResult<Query, Error>

type GraphMutationFunction = (
  props: GraphProps &
    UseMutationOptions<Mutation, Error, Record<string, any>, unknown> & {
      gqlKey: keyof CustomMutations
      onSettled?: () => Promise<void>
      onError?: () => void
    }
) => UseMutationResult<Mutation, Error, Record<string, any>, unknown>

type RestProps = {
  endpoint: string
  msalRequest?: SilentRequest | RedirectRequest
}

type QueryFunction = (
  props: RestProps &
    UseQueryOptions & {
      alwaysThrowOnError?: boolean
    }
) => UseQueryResult<object, Error>

export type ErrorWithResponse = Error & {
  response: Response
}

const shouldThrowError = (error: ErrorWithResponse) =>
  error.response?.status === 401 || // all Unauthorized errors will go to the Error Boundary
  error.response?.status === 403 || // all Forbidden errors will go to the Error Boundary
  error.response?.status >= 500 // all other server errors will go to the Error Boundary

const buildUrl = (endpoint: string) =>
  [configService().get('API_HOST_URI') ?? '', endpoint].join('/')

/**
 * Wraps a GraphQL query in a useQuery hook that requires authentication.
 * @param queryKey A unique key for the query.
 * @param gql The GQL object to define the query.
 * @param msalRequest The MSAL request object to use for authentication, defaults to Read scope.
 */
export const useAuthenticatedGraphQuery: GraphQueryFunction = ({
  queryKey,
  gql,
  queryParams,
  msalRequest = loginRequest,
  enabled = true,
  alwaysThrowOnError = false,
}) => {
  const accessToken = useGetAuthToken(msalRequest, queryKey)
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  })
  // Note: Adding a retry prop will ignore defaults set in the QueryClient.
  return useQuery({
    queryKey,
    queryFn: () => request(buildUrl('api/graphql'), gql ?? '', queryParams ?? {}, headers),
    enabled: enabled && !!accessToken, // Don't send the request if the access token is not available yet
    // If alwaysThrowOnError is true, all errors will go to the Error Boundary after the retries
    // Otherwise, conditionally throw error if shouldThrowError is true
    throwOnError: (error: ErrorWithResponse) => alwaysThrowOnError || shouldThrowError(error),
  })
}

/**
 *
 * @param {Object} params - Parameters for the mutation.
 * @param {string} params.gql - The GraphQL mutation string.
 * @param {string} params.gqlKey - The key to access the mutation result in the response.
 * @param {() => Promise<void>} params.onSettled - Optional function to run as soon as mutation starts execution. Use this to invalidate cache for non-optimistic updates.
 * @param {() => void} params.onError - Optional function to run if there was an error returned by the mutation. Use this to invalidate cache for optimistic updates.
 * @param {Object} [params.msalRequest=loginRequest] - The MSAL request object to use for authentication, defaults to Read scope.
 * @param {Object} params.mutationOptions - Additional options for `useMutation()`.
 *
 * @returns {MutationResult} The result of the mutation.
 *
 * @throws {Error} If the mutation response indicates a failure.
 */
export const useAuthenticatedGraphMutation: GraphMutationFunction = ({
  gql,
  gqlKey,
  onSettled,
  onError,
  msalRequest = loginRequest,
  ...mutationOptions
}) => {
  // TODO: Remove duplication between the gql and gqlKey parameters.
  const accessToken = useGetAuthToken(msalRequest, [Date.now().toString()])
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  })
  return useMutation<Mutation, ErrorWithResponse, Record<string, any>>({
    mutationFn: async (variables: Record<string, any>) => {
      const response = await request<Mutation>(
        buildUrl('api/graphql'),
        gql ?? '',
        variables ?? {},
        headers
      )

      const mutationResponse: MutationResponse | null = response[gqlKey]

      if (mutationResponse && mutationResponse.success === false) {
        throw new Error(mutationResponse.errorMessage || 'An error occurred')
      }
      return response
    },
    // Conditionally throw error if shouldThrowError is true (throwOnError results in React Error Boundary catching it)
    throwOnError: (error) => shouldThrowError(error),
    onError: (error) => {
      if (!shouldThrowError(error)) {
        // Conditionally show toast message instead of throwing error if shouldThrowError is false
        showErrorNotification(error.message)
      }
      // If defined, execute additional onError behaviour passed from the top, i.e. invalidation of cache for optimistic updates
      if (onError) {
        onError()
      }
    },
    onSettled: async () => {
      // If defined, execute onSettled behaviour passed from the top, i.e. invalidation of cache for non-optimistic updates
      if (onSettled) {
        // The promise has to be returned from the query invalidation, so that the mutation stays in `pending` state until the refetch is finished
        return onSettled()
      }
      return null
    },
    ...mutationOptions,
  })
}

/**
 * Wraps a REST query in a useQuery hook that requires authentication.
 * @param queryKey A unique key for the query.
 * @param endpoint The endpoint to query.
 * @param msalRequest The MSAL request object to use for authentication, defaults to Read scope.
 */
export const useAuthenticatedRESTQuery: QueryFunction = ({
  queryKey,
  endpoint,
  msalRequest = loginRequest,
  alwaysThrowOnError = false,
}) => {
  const accessToken = useGetAuthToken(msalRequest, queryKey)
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  })
  return useQuery({
    queryKey,
    queryFn: async () => {
      const response: Response = await fetch(buildUrl(endpoint), { headers })
      const json = await response.json()
      return json
    },
    enabled: !!accessToken, // Don't send the request if the access token is not available yet
    // If alwaysThrowOnError is true, all errors will go to the Error Boundary after the retries
    // Otherwise, conditionally throw error if shouldThrowError is true
    throwOnError: (error: ErrorWithResponse) => alwaysThrowOnError || shouldThrowError(error),
  })
}
