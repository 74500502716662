import ReactDOM from 'react-dom/client'
import App from './app'
import { createMsalInstance } from './auth-config'
import { configService } from '@/services/config-service'

/* eslint-disable */
/**
 * Ignoring linting issues for now as likely to rip conditional mocking out once
 * auth/fixtures set up for e2e. Solution below came from: https://sapegin.me/blog/react-testing-5-playwright/
 */
const enableMocking = async () => {
  if (!configService().get('ENABLE_MOCKING')) {
    return
  }

  const { worker } = await import('../test-utils/mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return await worker.start()
}
/* eslint-enable */

// For Playwright tests, we currently want to mock API requests/bypass auth
// TODO: implement e2e testing so we can test with auth/fixture data and remove this
enableMocking().then(() => {
  // Wrap our app in a Msal instance so that we allow only authenticated users to access the app
  // See authConfig.ts for autologin on app load behaviour and other Entra ID config
  createMsalInstance().then((msalInstance) => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <App msalInstance={msalInstance} />
    )
  })
})
