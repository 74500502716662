import { useMemo } from 'react'
import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from 'mantine-react-table'
import { PaginationState, ColumnFiltersState, OnChangeFn } from '@tanstack/react-table'
import { ListTypedWoolLotsResponse, TypedWoolLot } from '@/loom-gql/graphql'
import { hubTableDefaultProps } from '@/components/shared/table/table'
import { formatDate } from '@/helpers/format-date'

// TODO: This is not a sustainable solution, all GraphQL response attributes
// should be optional.
type TypedWoolLotTableData = Pick<
  TypedWoolLot,
  | 'purchaseBrand'
  | 'stationRecordNumber'
  | 'saleNumber'
  | 'season'
  | 'code'
  | 'bales'
  | 'internalReferenceCode'
  | 'typeCurrent'
  | 'internalComment'
  | 'actions'
  | 'lastTyped'
>
type TypedWoolLotTableResponse = Omit<ListTypedWoolLotsResponse, 'results'> & {
  results: Array<TypedWoolLotTableData>
}

export function TypedLotsTable({
  data,
  isError,
  isFetching,
  isLoading,
  pagination,
  setPagination,
  columnFilters,
  setColumnFilters,
}: {
  data: TypedWoolLotTableResponse | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  pagination: PaginationState
  setPagination: OnChangeFn<PaginationState>
  columnFilters: ColumnFiltersState
  setColumnFilters: OnChangeFn<ColumnFiltersState>
}) {
  const columns = useMemo<MRT_ColumnDef<TypedWoolLotTableData>[]>(
    () => [
      {
        accessorKey: 'purchaseBrand',
        header: 'Brand',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'stationRecordNumber',
        header: 'P Org',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'saleNumber',
        header: 'Sale',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'season',
        header: 'Season',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'code',
        header: 'Lot',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'bales',
        header: 'Bales',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'internalReferenceCode',
        header: 'Int Ref',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'typeCurrent',
        header: 'Type',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'internalComment',
        header: 'Comment',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        enableColumnFilter: false,
      },
      {
        id: 'lastTyped',
        header: 'To Talman',
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        enableColumnFilter: true,
        accessorFn: (row) => (row.lastTyped == null ? null : new Date(row.lastTyped)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY hh:mm A'),
      },
    ],
    []
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data: data?.results ?? [],
    initialState: {
      ...hubTableDefaultProps.initialState,
      showColumnFilters: true,
    },
    state: {
      isLoading,
      columnFilters,
      pagination,
      showProgressBars: isFetching,
      showAlertBanner: isError,
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data for table',
        }
      : undefined,
    // Pagination
    manualPagination: true,
    rowCount: data?.totalResultCount,
    pageCount: data?.totalPageCount,
    onPaginationChange: setPagination,
    // Column filters
    enableFilters: true,
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
  })

  return <MantineReactTable table={table} />
}
