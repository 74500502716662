import { useState } from 'react'
import { Stack, Title } from '@mantine/core'
import { UseQueryResult } from '@tanstack/react-query'
import { PaginationState, ColumnFiltersState } from '@tanstack/react-table'
import { TypedLotsTable } from '@/components/typing/typed-lots/typed-lots-table'
import { Query, ListTypedWoolLotsOrderByOptions } from '@/loom-gql/graphql'
import { getDatetimeFromDateFilter, DatePosition } from '@/helpers/get-datetime-from-date-filter'
import { TypedLotsListGql } from '@/graphql/queries/typed-lots-table.graphql'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { PAGE_TITLES } from '@/constants/page-titles'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'

export function TypedLotsPage() {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'lastTyped',
      value: [new Date(), new Date()],
    },
  ])

  const { data, isError, isFetching, isLoading }: UseQueryResult<Query> =
    useAuthenticatedGraphQuery({
      queryKey: ['listTypedLots', pagination, columnFilters],
      gql: TypedLotsListGql,
      queryParams: {
        currentPage: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        orderBy: ListTypedWoolLotsOrderByOptions.LastTyped,
        orderByAscending: false,
        from: getDatetimeFromDateFilter(columnFilters, 'lastTyped', DatePosition.START),
        to: getDatetimeFromDateFilter(columnFilters, 'lastTyped', DatePosition.END),
      },
    })

  return (
    <PageContainer title={PAGE_TITLES.TYPED_LOTS}>
      <Stack>
        <Title>Typed Lots</Title>
        <TypedLotsTable
          data={data?.listTypedWoolLots}
          isError={isError}
          isFetching={isFetching}
          isLoading={isLoading}
          pagination={pagination}
          setPagination={setPagination}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
        />
      </Stack>
    </PageContainer>
  )
}
