import { gql } from 'graphql-request'

export const GetStationHeaderGql = gql`
  query stationHeader($recordNumber: String!) {
    stationHeader(recordNumber: $recordNumber) {
      brand
      isOnHold
      recordNumber
      stationsInGroup {
        brand
        recordNumber
      }
    }
  }
`
