import { useParams } from 'react-router-dom'
import { Grid, TextInput, Stack, Title } from '@mantine/core'
import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'
import { GetStationDetailsGql } from '@/graphql/queries/get-station-details.graphql'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'

export function StationDetailsPage() {
  const { stationRecordNumber } = useParams()

  const { data, isError } = useAuthenticatedGraphQuery({
    queryKey: ['stationDetails', stationRecordNumber],
    gql: GetStationDetailsGql,
    queryParams: { recordNumber: stationRecordNumber },
  })

  return (
    <Stack data-testid="station-details">
      <Title order={2}>Station Details</Title>
      {isError && (
        <IconAlert level={AlertLevel.ERROR} title="Error" message="Failed to fetch station data" />
      )}
      {data?.stationDetails === null ? (
        <IconAlert
          level={AlertLevel.ERROR}
          title="Station details not found"
          message={`No station found with record number ${stationRecordNumber}`}
        />
      ) : (
        <Grid>
          <Grid.Col span={6}>
            <Stack>
              <TextInput
                label="Farm Group"
                value={data?.stationDetails?.stationGroupDescription ?? ''}
                readOnly
                variant="filled"
              />
              <TextInput label="Trading Name" value="" readOnly variant="filled" />
            </Stack>
          </Grid.Col>
          <Grid.Col span={6}>
            <Stack>
              <TextInput label="NZM Area Manager" value="" readOnly variant="filled" />
            </Stack>
          </Grid.Col>
        </Grid>
      )}
    </Stack>
  )
}
