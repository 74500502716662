import { ColumnFiltersState } from '@tanstack/react-table'

/**
 * Retrieves a date string from a Mantine React Table filters based on the specified name and position.
 *
 * If the from date is requested, the time will be set to 00:00:00.000 in the client's timezone.
 * If the to date is requested, the time will be set to 23:59:59.999 in the client's timezone.
 * The dates are then converted to ISO strings.
 * This is done to ensure that the date range is inclusive of the entire day when converted to UTC.
 *
 * @param columnFilters - The state of the column filters.
 * @param columnName - The name of the column filter to retrieve the date from.
 * @param position - The position of the date to retrieve (START for start date, END for end date).
 * @returns The datetime string at the specified position or undefined if not found.
 */

export enum DatePosition {
  START = 0,
  END = 1,
}

export function getDatetimeFromDateFilter(
  columnFilters: ColumnFiltersState,
  columnName: string,
  position: DatePosition
): string | undefined {
  const date =
    ((columnFilters.find((dict) => dict.id === columnName)?.value as [Date, Date])?.[
      position
    ] as Date) || undefined

  if (!date) return undefined

  if (position === DatePosition.START) {
    date.setHours(0, 0, 0, 0)
  } else {
    date.setHours(23, 59, 59, 999)
  }
  return date.toISOString()
}
