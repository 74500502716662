export type Config = {
  MSAL_AUTHORITY: string
  MSAL_CLIENT_ID: string
  MSAL_REDIRECT_URI: string
  MSAL_LOOM_APP_URI: string
  API_HOST_URI: string
  DEBUG_AUTH: string
  ENABLE_MOCKING: string
}

let singletonService: ConfigService | null = null

class ConfigService {
  private config: Config | null

  constructor() {
    const isDevOrTest = ['development', 'test', 'playwright'].includes(import.meta.env.MODE)
    const useDeployedConfig = !isDevOrTest

    // Azure Static Apps will inject the config as a global variable at request time
    if (useDeployedConfig) {
      if (!(window as any).NZM_CONFIG) {
        this.config = null
        throw new Error('Config not configured in deployed environment')
      }
      this.config = (window as any).NZM_CONFIG as Config
    } else {
      this.config = {
        MSAL_AUTHORITY: import.meta.env.VITE_MSAL_AUTHORITY ?? '',
        MSAL_CLIENT_ID: import.meta.env.VITE_MSAL_CLIENT_ID ?? '',
        MSAL_REDIRECT_URI: import.meta.env.VITE_MSAL_REDIRECT_URI ?? '',
        MSAL_LOOM_APP_URI: import.meta.env.VITE_MSAL_LOOM_APP_URI ?? '',
        API_HOST_URI: import.meta.env.VITE_API_HOST_URI ?? '',
        DEBUG_AUTH: import.meta.env.VITE_DEBUG_AUTH ?? '',
        ENABLE_MOCKING: import.meta.env.MODE === 'playwright' ? 'true' : '',
      }
    }
  }

  public get(key: keyof Config): string {
    if (!this.config) {
      throw new Error('Config not configured')
    }
    return this.config[key]
  }
}

export function configService(): ConfigService {
  if (!singletonService) {
    singletonService = new ConfigService()
  }

  return singletonService
}
