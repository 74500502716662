import { Maybe, GetTypingProgramDataResponse } from '@/loom-gql/graphql'
import { WoolLotDataProps } from '@/components/typing/program/typing-program-types'

export function processTypingData(
  originalData: Maybe<GetTypingProgramDataResponse> | undefined
): WoolLotDataProps | null {
  // Return null if original data is null or undefined
  if (originalData == null) {
    return null
  }

  // Create data object to avoid modifying original object
  // TODO: Enforce key must be from TypingProgramDetailsDataProps
  const processedData: { [key: string]: any } = {}

  // Define fields to modify with function to modify them
  const fieldsToModify: {
    [key: string]: appendPercentageFn | setNullIfZeroFn | convertToDateFn
  } = {
    coreTestBurrPercentage: appendPercentage,
    coreTestCoefficientOfVariationLaserscan: appendPercentage,
    coreTestMicron: setNullIfZero,
    coreTestMicronAirflow: setNullIfZero,
    coreTestMicronLaserscan: setNullIfZero,
    coreTestMicronOfda: setNullIfZero,
    coreTestSeedShivePercentage: appendPercentage,
    coreTestVegetableMatterBase: appendPercentage,
    coreTestYield1: appendPercentage,
    woolLotCommissionPaymentDate: convertToDate,
  }

  // Iterate over fields
  for (const [field, value] of Object.entries(originalData)) {
    const modifierFn = fieldsToModify[field]
    if (modifierFn) {
      // If modifier function, apply it
      processedData[field] = modifierFn(value)
    } else if (typeof value === 'number') {
      // Cast unmodified numbers to strings
      processedData[field] = value.toString()
    } else {
      // Otherwise, use original value
      processedData[field] = value
    }
  }

  // Join sale and season string
  if (processedData?.woolLotSaleNumber == null || processedData?.woolLotSeason == null) {
    processedData.woolLotSaleAndSeason = null
  } else {
    processedData.woolLotSaleAndSeason = `${processedData.woolLotSaleNumber}/${processedData.woolLotSeason}`
  }

  return processedData as WoolLotDataProps
}

type displayRangeFn = (min: number | null, max: number | null) => string | null
export const displayRange: displayRangeFn = (min, max) => {
  if (!min || !max) {
    return ''
  }
  return `${min} - ${max}`
}

type appendPercentageFn = (value: string | null) => string | null
export const appendPercentage: appendPercentageFn = (value) => (value == null ? null : `${value}%`)

type setNullIfZeroFn = (value: number | null) => string | null
export const setNullIfZero: setNullIfZeroFn = (value) => {
  if (value === null) {
    return null
  }
  return value === 0 ? null : value.toString()
}

type convertToDateFn = (value: string | null) => Date | null
export const convertToDate: convertToDateFn = (value) => (value == null ? null : new Date(value))
