import { useMemo } from 'react'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { useMantineTheme } from '@mantine/core'
import { hubTableDefaultProps } from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { ContractDetail } from '@/loom-gql/graphql'
import { displayRange } from '@/helpers/typing/program/typing-program-data'

interface ContractsTableProps {
  data?: ContractDetail[]
  noDataMessage?: string
  isLoading?: boolean
}

export const ContractsTable = ({
  data = [],
  noDataMessage = 'No contracts available for this wool lot',
  isLoading,
}: ContractsTableProps) => {
  const theme = useMantineTheme()

  const columns = useMemo<MRT_ColumnDef<ContractDetail>[]>(
    () => [
      {
        accessorKey: 'contractName',
        header: 'Contract Name',
      },
      {
        accessorKey: 'outstanding',
        header: 'Outstanding',
        mantineTableBodyCellProps: ({ cell }) => ({
          bg: cell.getValue() === 0 ? undefined : theme.colors.yellow[0],
        }),
      },
      {
        accessorKey: 'contracted',
        header: 'Contracted',
      },
      {
        accessorKey: 'targetMicronMin',
        header: 'Mic Range',
        accessorFn: (row) => displayRange(row.targetMicronMin, row.targetMicronMax),
      },
      {
        accessorKey: 'vmMax',
        header: 'VM Max',
      },
      {
        accessorKey: 'greasyLengthRangeMin',
        header: 'Len Range',
        accessorFn: (row) => displayRange(row.greasyLengthRangeMin, row.greasyLengthRangeMax),
      },
      {
        accessorKey: 'nktMin',
        header: 'NKT Min',
      },
    ],
    []
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    state: {
      showSkeletons: isLoading,
    },
    enablePagination: false,
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Season Contracts',
    },
  })

  return <MantineReactTable table={table} />
}
