import { gql } from 'graphql-request'

export const GetWoolStoreToolGql = gql`
  query woolStoreTool($referenceNumber: String!) {
    woolStoreTool(request: { internalReferenceCode: $referenceNumber }) {
      baleHeaderPurchaseDescription
      baleHeaderPurchaseBrand
      baleHeaderSampleLocation
      coreTestBoxLocation
      coreTestId
      woolLotId
      woolLotCode
      woolLotPreviousSaleNumber
      woolLotPreviousSeason
      woolLotPreviousWoolLot
      woolLotRecordNumber
      woolLotRecordSeason
      woolLotSaleNumber
      woolLotBales
      woolLotTypeCurrent
      woolStorageCentreCode
      woolStoreStatus
    }
  }
`
