import { Title, Text, Stack, Button } from '@mantine/core'
import { FallbackProps } from 'react-error-boundary'

export const MenuError = ({ error, resetErrorBoundary }: FallbackProps) => (
  <Stack p="md" ta="center" align="center">
    <Title order={1} size="h4">
      {error.name}
    </Title>
    <Text size="sm">{error.message}</Text>
    <Button onClick={resetErrorBoundary}>Try again</Button>
  </Stack>
)
