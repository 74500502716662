import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { HomePage } from '@/pages/home.page'
import { TypingProgram } from '@/pages/typing/program/typing-program.page'
import { TypedLotsPage } from '@/pages/typing/typed-lots/typed-lots.page'
import { WoolStoreToolsPage } from './pages/wool-store-tools.page'
import { StationSearchPage } from '@/pages/stations/station-search.page'
import { ProtectedRoute } from '@/components/protected-route'
import { AppShell } from '@/components/shared/app-shell/app-shell'
import { PageNotFound } from '@/pages/page-not-found.page'
import { ErrorPage } from '@/pages/error.page'
import { StationPage } from '@/pages/stations/station.page'

export const routerConfig = [
  {
    element: (
      <ErrorBoundary fallbackRender={ErrorPage}>
        <ProtectedRoute />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/',
        element: <AppShell />,
        children: [
          {
            path: '/',
            element: <HomePage />,
          },
          {
            path: '/stations/',
            element: <StationSearchPage />,
          },
          {
            path: '/stations/:stationRecordNumber',
            element: <StationPage />,
          },
          {
            path: '/stations/:stationRecordNumber/:stationTabValue',
            element: <StationPage />,
          },
          {
            path: '/stations/:stationRecordNumber/:stationTabValue/:woolDataTabValue',
            element: <StationPage />,
          },
          {
            path: '/typing/program',
            element: <TypingProgram />,
          },
          {
            path: '/typing/typed-lots',
            element: <TypedLotsPage />,
          },
          {
            path: '/wool-store-tools',
            element: <WoolStoreToolsPage />,
          },
          {
            path: '*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
]

const router = createBrowserRouter(routerConfig)

export function Router() {
  return <RouterProvider router={router} />
}
