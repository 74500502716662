import { Container, Stack, Title } from '@mantine/core'
import {
  IconScan,
  IconTag,
  IconCopy,
  IconTrademark,
  IconListDetails,
  IconBox,
  IconMapPin,
  IconBuildingWarehouse,
  IconFingerprint,
  IconTags,
  IconCalendarDue,
  IconActivityHeartbeat,
} from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { WoolLotLookup } from '@/components/shared/wool-lot-lookup/wool-lot-lookup'
import { WoolStoreWidgets } from '@/components/wool-store/wool-store-widgets'
import { WoolStoreWidgetValues } from '@/components/wool-store/wool-store-widget-values'
import {
  useAuthenticatedGraphMutation,
  useAuthenticatedGraphQuery,
} from '@/hooks/use-authenticated-query'
import { WoolStoreStatus } from '@/loom-gql/graphql'
import { GetWoolStoreToolGql } from '@/graphql/queries/get-wool-store-tool.graphql'
import { RAGStatus } from '@/constants/rag-status'
import { UpdateBoxLocationGql } from '@/graphql/mutations/update-box-location.graphql'
import { EnterOrScanAlert } from '@/components/shared/enter-or-scan-alert'
import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'
import { PAGE_TITLES } from '@/constants/page-titles'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'
import { outMessage } from '@/pages/constants'

export function WoolStoreToolsPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const referenceNumberFromParams = queryParams.get('reference')
  const [noResults, setNoResults] = useState<boolean>(false)
  const [lastReferenceNumber, setLastReferenceNumber] = useState<string | null>(null)
  const queryClient = useQueryClient()

  const handleReferenceNumberUpdate = (newReferenceNumber: string | null) => {
    // update query parameters
    if (newReferenceNumber === null) {
      if (referenceNumberFromParams) {
        queryParams.set('reference', referenceNumberFromParams)
      } else {
        queryParams.delete('reference')
      }
    } else {
      queryParams.set('reference', newReferenceNumber)
      if (
        newReferenceNumber === referenceNumberFromParams ||
        newReferenceNumber === lastReferenceNumber
      ) {
        refetch()
        return
      }
    }

    setLastReferenceNumber(newReferenceNumber)
    // create new search string and navigate to it
    const newSearch = `?${queryParams.toString()}`
    navigate({ search: newSearch })
  }

  // Get the wool store program data
  const {
    data: woolStoreData,
    isLoading: wsLoading,
    isFetching: wsFetching,
    refetch,
  } = useAuthenticatedGraphQuery({
    queryKey: ['woolStoreData', referenceNumberFromParams],
    gql: GetWoolStoreToolGql,
    queryParams: { referenceNumber: referenceNumberFromParams },
    enabled: !!referenceNumberFromParams,
    alwaysThrowOnError: true,
  })

  const woolStoreDataLoadingOrFetching = wsLoading || wsFetching

  // Set variable which controls if no results message should be displayed
  useEffect(() => {
    setNoResults(
      !woolStoreDataLoadingOrFetching && !!woolStoreData && !woolStoreData?.woolStoreTool
    )
  }, [woolStoreDataLoadingOrFetching, woolStoreData])

  const blUpdate = useAuthenticatedGraphMutation({
    gql: UpdateBoxLocationGql,
    gqlKey: 'setBoxLocation',
    onError: async () =>
      // Execute 'cache busting' by running invalidateQueries when mutation returns any errors
      queryClient.invalidateQueries({ queryKey: ['woolStoreData', referenceNumberFromParams] }),
  })

  const setBoxLocation = (newBoxLocationInput: string | null) => {
    let boxLocation: string | null = newBoxLocationInput
    // This is to support scanning special "OUT" bar-codes which should result in clearing the box location field
    if (newBoxLocationInput?.includes(outMessage)) {
      boxLocation = ''
    }
    blUpdate.mutate({ coreTestId: woolStoreData?.woolStoreTool?.coreTestId, boxLocation })
    // To avoid refetching on mutation success, we need to assume mutation will be successful and override current typing program data
    queryClient.setQueryData(['woolStoreData', referenceNumberFromParams], {
      woolStoreTool: {
        ...woolStoreData?.woolStoreTool,
        coreTestBoxLocation: boxLocation,
      },
    })
  }

  // Get a WoolStoreWidgetValues[] to show in WoolStoreWidgets
  const woolStoreWidgetData: WoolStoreWidgetValues[] = [
    {
      icon: <IconScan size="32" />,
      name: 'Reference',
      value: woolStoreData?.woolStoreTool?.woolLotRecordNumber,
    },
    {
      icon: <IconTag size="32" />,
      name: 'Current Sale',
      value: woolStoreData?.woolStoreTool?.woolLotSaleNumber,
    },
    {
      icon: <IconCopy size="32" />,
      name: 'Current Lot',
      value: woolStoreData?.woolStoreTool?.woolLotCode,
    },
    {
      icon: <IconTrademark size="32" />,
      name: 'Brand',
      doubleWide: true,
      value: woolStoreData?.woolStoreTool?.baleHeaderPurchaseBrand,
    },
    {
      icon: <IconListDetails size="32" />,
      name: 'Description',
      value: woolStoreData?.woolStoreTool?.baleHeaderPurchaseDescription,
    },
    {
      icon: <IconBox size="32" />,
      name: 'Bales',
      value: woolStoreData?.woolStoreTool?.woolLotBales,
    },
    {
      icon: <IconMapPin size="32" />,
      name: 'Box Location',
      value: woolStoreData?.woolStoreTool?.coreTestBoxLocation,
    },
    {
      icon: <IconBuildingWarehouse size="32" />,
      name: 'Storage Centre',
      value: woolStoreData?.woolStoreTool?.woolStorageCentreCode,
    },
    {
      icon: <IconMapPin size="32" />,
      name: 'Sample Location',
      value: woolStoreData?.woolStoreTool?.baleHeaderSampleLocation,
    },
    {
      icon: <IconFingerprint size="32" />,
      name: 'Type',
      value: woolStoreData?.woolStoreTool?.woolLotTypeCurrent,
    },
    {
      icon: <IconTags size="32" />,
      name: 'Previous Sale',
      value: woolStoreData?.woolStoreTool?.woolLotPreviousSaleNumber,
    },
    {
      icon: <IconCalendarDue size="32" />,
      name: 'Previous Season',
      value: woolStoreData?.woolStoreTool?.woolLotPreviousSeason,
    },
    {
      icon: <IconCopy size="32" />,
      name: 'Previous Lot',
      value: woolStoreData?.woolStoreTool?.woolLotPreviousWoolLot,
    },
    {
      icon: <IconActivityHeartbeat size="32" />,
      name: 'Status',
      status:
        woolStoreData?.woolStoreTool?.woolStoreStatus === WoolStoreStatus.SoldOrBin
          ? RAGStatus.GREEN
          : RAGStatus.AMBER,
      value:
        woolStoreData?.woolStoreTool?.woolStoreStatus === WoolStoreStatus.SoldOrBin
          ? 'Sold/Bin'
          : 'Unsold',
    },
  ]

  return (
    <PageContainer title={PAGE_TITLES.WOOL_STORE_TOOLS}>
      <Container my="md">
        <Stack>
          <Title>Wool Store Tools</Title>
          <EnterOrScanAlert
            title="Enter or scan a reference number"
            message="Scan the sample barcode or select the Enter Reference button."
          />
          <WoolLotLookup
            setReferenceNumber={handleReferenceNumberUpdate}
            setBoxLocation={setBoxLocation}
            lookupResponse={{
              woolLotRecordNumber: woolStoreData?.woolStoreTool?.woolLotRecordNumber,
              coreTestId: woolStoreData?.woolStoreTool?.coreTestId,
              isLoading: woolStoreDataLoadingOrFetching,
              isSuccess: !woolStoreDataLoadingOrFetching && !!woolStoreData?.woolStoreTool,
            }}
            boxLocationValue={woolStoreData?.woolStoreTool?.coreTestBoxLocation}
          />
          {noResults && referenceNumberFromParams && (
            <IconAlert level={AlertLevel.WARNING} title="No results found" />
          )}
          {!!woolStoreData?.woolStoreTool && (
            <WoolStoreWidgets
              woolStoreWidgetData={woolStoreWidgetData}
              isLoading={woolStoreDataLoadingOrFetching}
            />
          )}
        </Stack>
      </Container>
    </PageContainer>
  )
}
