import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { StationSearchGql } from '@/graphql/queries/station-search.graphql'
import { SEARCH_TERM_PARAM_NAME } from '@/pages/stations/constants'

export function useStationSearch(limit: number = 100) {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const searchTermFromParams = queryParams.get(SEARCH_TERM_PARAM_NAME)
  const [searchTermEntry, setSearchTermEntry] = useState(searchTermFromParams ?? '')

  const {
    data: stationSearchData,
    isLoading,
    isFetching,
    isError,
  } = useAuthenticatedGraphQuery({
    queryKey: ['stationSearch', searchTermFromParams],
    gql: StationSearchGql,
    queryParams: { searchTerm: searchTermFromParams, limit },
    enabled: !!searchTermFromParams,
  })

  const handleSearchRequest = () => {
    const newSearch = encodeURIComponent(searchTermEntry)
    navigate(`/stations?search-term=${newSearch}`)
  }

  useEffect(() => {
    setSearchTermEntry(searchTermFromParams ?? '')
  }, [searchTermFromParams])

  return {
    searchTermEntry,
    setSearchTermEntry,
    handleSearchRequest,
    stationSearchData,
    isLoading,
    isFetching,
    isError,
  }
}
